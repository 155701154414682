.mobileNav .topBar{display: flex;align-items: center;justify-content: space-between;width: 100%;position:relative;}
.mobileNav .searchBox{position:relative; width:100%;}
.mobileNav .searchIcon{position:absolute; left:10px; top:27px;    color: #999;}
.mobileNav .searchBox .form-control{padding-left:2rem;    margin-top: 1rem;}
.mobileNav .topBar .navbar-toggler{padding:0px; border:none;}
.mobileNav .shoppingCart{color:#fff; font-size: 1.25rem;}
.mobileNav #responsive-navbar-nav{position: absolute;top: 34px;z-index: 9;width: 100%;}
.mobileNav{display:none;}
@media (max-width: 767px){
	.desktopNav{display:none;}
	.mobileNav{display:block;}
}

.list-group-custom {
	top: 46px;
    background-color: white;
    list-style-type: none;
    margin: 0 0 0 10px;
    padding: 0;
    position: absolute;
	z-index: 9999;
    width: 29%;
}

.list-group-custom > li {
    border-color: gray;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 1px 0;
    padding-left: 5px;
	padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

.list-group-custom > li:last-child {
  border-bottom: 1px solid gray;
}